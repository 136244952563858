<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:48:11
 * @LastEditTime: 2023-04-11 16:48:24
 * @Descripttion: 【互动中心】焦点关注
-->
<style lang="scss" scoped>
.news {
    @include innerPage;
    @include pageHead(-42px);
    @include pageFoot;

    .page-inner {
        height: calc(100% - 162px);
        box-sizing: border-box;
        padding-top: 36px;

        .news-list {
            width: 100%;
            height: 100%;
            margin-bottom: 26px;
            overflow: hidden;
            overflow-y: auto;
            flex-wrap: wrap;
            @include flexBox(flex-start, flex-start);

            &--wrapper {
                width: calc(100% + 20px);
                height: calc(100% - 100px);

                &.no-data {
                    width: 100%;
                    height: calc(100% - 104px);
                    background: #fff;
                    border-radius: 10px;
                    margin: 0 auto 30px;
                }
            }

            .news-item {
                width: calc((100% - 80px) / 4);
                height: 362px;
                border-radius: 10px;
                background: #fff;
                margin-right: 20px;
                cursor: pointer;

                &:nth-child(n + 5) {
                    margin-top: 36px;
                }

                .el-image {
                    width: 100%;
                    height: 218px;
                    border-radius: 10px;
                }

                &:hover {
                    box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15), 5px 5px 27px -10px rgba(108, 78, 203, 0.15);
                }
            }

            .news-info {
                box-sizing: border-box;
                padding: 0 28px;

                h5 {
                    line-height: 24px;
                    color: #3c3b3b;
                    margin-top: 20px;
                }

                .desc {
                    line-height: 24px;
                    color: #787878;
                    margin-top: 4px;
                }
            }

            .news-foot {
                height: 58px;
                border-top: 1px solid rgba($color: #585858, $alpha: .14);
                margin-top: 10px;
                @include flexBox;

                .iconfont {
                    font-size: 18px;
                    color: #666;
                    margin-right: 6px;
                }

                span {
                    color: #949495;
                }

                .views {
                    margin: 0 20px 0 auto;
                    color: #7a7a7a;

                    .pf_bold {
                        font-size: 22px;
                        color: #6340c8;
                    }
                }

                .likes {
                    padding: 0;
                    min-width: auto;
                    color: #4a4a4a;

                    .iconfont {
                        font-size: 24px;
                        color: #d6d8e1;
                    }

                    &.did .iconfont {
                        color: #fc546b;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <section class="news">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
            </div>
        </div>
        <div class="page-inner">
            <div class="news-list--wrapper" :class="{ 'no-data': $isEmpty(tableData) }">
                <ul class="news-list" v-show="!$isEmpty(tableData)">
                    <li class="news-item" v-for="item in tableData" :key="item.plinf_id"
                        @click="$router.push({ name: 'INTERACTION_NEWSDETAIL', params: { id: item.plinf_id } })">
                        <el-image fit='cover' :src="formatfile(item.plinf_image)" />
                        <div class="news-info">
                            <h5 class="bold line-text--1st">{{ item.plinf_title }}</h5>
                            <p class="desc line-text--1st">{{ item.plinf_content }}</p>
                            <div class="news-foot">
                                <i class="iconfont">&#xe61a;</i>
                                <span>{{ item.create_time | formatTime('YYYY/MM/DD') }}</span>
                                <span class="views"><b class="pf_bold">{{ item.plinf_read_num || 0 }}</b>浏览</span>
                                <el-button type="text" class="likes" :class="{ did: item.is_praise == 1 }"
                                    @click.stop="item.is_praise = item.is_praise == 1 && 2 || 1; likeNews(item.plinf_id)">
                                    <i class="iconfont">&#xe641;</i>
                                    <span class="light">点赞</span>
                                </el-button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="no-data--empty" v-if="$isEmpty(tableData)">
                    <img src="@assets/images/no-data3.png" alt="">
                    <p>暂无消息数据哦~</p>
                </div>
            </div>
            <!-- 分页控件 -->
            <div class="custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" :size="tablePage.pageSize"
                    @pageChange="flippingPage" />
            </div>
        </div>
    </section>
</template>

<script>
import { $newsList, $likeNews } from "@api/interaction"
import { formatFile } from "@utils";
import customPagination from "@comp/customPagination"
export default {
    name: 'interaction_news',
    components: { customPagination },
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
    },
    data() {
        return {
            tableData: [],
            tablePage: {
                pageIndex: 1,
                pageSize: 12,
                total: 0
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 资讯列表 */
        async getData() {
            let { data: res } = await $newsList({ pageindex: this.tablePage.pageIndex }, this.tablePage.pageSize);
            this.tableData = res.data;
            this.tablePage.total = res.allcount;
            this.$forceUpdate();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /** 点赞、取消点赞 */
        async likeNews(id) {
            let res = await $likeNews(id);
            if (res) {
                this.getData();
            }
        }
    },
}
</script>